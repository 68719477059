<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col offset-md="1" offset-lg="2">
        <Container :paddedTopBottom="false">
          <h2>Jeroen Demeestere</h2>
          <p>
            Na een jeugd gevuld met sport en competitie was het voor mij snel
            duidelijk dat ik later in de gezondheidszorg wou terecht komen. Ik
            besloot kinesitherapie studeren in Leuven maar bleef na deze 5
            jarige opleiding op mijn honger zitten. Op zoek naar een meer
            holistische benadering besloot ik osteopathie te sturen in Gent,
            waar ik in 2017 mijn D.O. diploma behaalde.
          </p>
          <p>
            Osteopathie is voor mij een passie en biedt mij die antwoorden waar
            andere vakgebieden tekort schieten. Gezondheid is immers een complex
            gegeven dat een ruime en grondige aanpak verdient.
          </p>
          <p>
            Je kan bij mij terecht met een waaier van pijnklachten gaande van
            acute pijnproblemen (nek/rug…) tot meer complexe en chronische
            ziektebeelden. (hoofdpijnen, spijsverteringsproblemen, …)
          </p>
          <p>
            Reeds 10 jaar ben ik actief in de sportwereld als therapeut en
            osteopaat. Als myofasciaal expert behandel ik ook vaak
            sportblessures, vanzelfsprekend kan je ook hiervoor bij mij terecht
            in de praktijk.
          </p>
          <p>
            Daarnaast vind je me vaak aan de slag als docent. Kennis delen met
            collega’s over ons vak vind ik een absolute meerwaarde. Deze
            attitude vond ik terug in deze groepspraktijk en zo ontstond deze
            fijne samenwerking. Je kan bij mij terecht op woensdag.
          </p>
          <!-- <h3>Specialismen</h3>
          <p></p> -->
          <h3>Opleiding en Nascholing</h3>
          <ul>
            Master in de revalidatiewetenschappen en kinesitherapie aan de
            katholieke universiteit Leuven
          </ul>
          <ul>
            Osteopaat D.O. aan de Belgian School of Osteopathie Gent
          </ul>
          <ul>
            Postgraduaat manuele therapie aan de universiteit van Gent
          </ul>
          <ul>
            Opleiding tot myofasciaal therapeut, dry needling bij Trigger
          </ul>
          <ul>
            Postgraduaat neutraal manipulaties aan het Fico Antwerpen
          </ul>
          <ul>
            Klinische kinesiologie cursus
          </ul>
          <ul>
            Docent dry needling
          </ul>
          <ul>
            Osteopaat Decospan volley team Menen Liga A
          </ul>
          <ul>
            Assistent opleiding osteopathie Flanders international college of
            oteopathy
          </ul>
          <ul>
            Osteopathy &amp; sports aan het IAO in Gent (i.o.)
          </ul>

          <img src="../../assets/team/jeroen.png" alt="Christijn" />
        </Container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Container from "../../components/Container.vue";
export default {
  name: "",
  components: { Container }
};
</script>

<style lang="less" scoped>
h2 {
  font-size: 2rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
  margin-top: 3rem;
}

h3 {
  font-size: 1.4rem;
  text-transform: uppercase;
  margin-bottom: 0.2rem;
  color: #747769;
  em {
    font-style: normal;
    color: #747769;
  }
}

img {
  max-width: 100%;
}
</style>
